// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workflow{
    padding: 80px 180px;
    background-color: var(--dark-beige);
}

.phases{
    max-width: 500px;
}




@media (max-width: 2560px) {
    .workflow{
        padding: 120px 340px;
    }
}
  
@media (max-width: 1440px) {
    .workflow{
        padding: 80px 180px;
    }
}


@media (max-width: 1024px) {
    .workflow{
        padding: 80px 120px;
    }
}
    
@media (max-width: 768px) {

    .workflow{
        padding: 80px 60px;
    }
    }`, "",{"version":3,"sources":["webpack://./src/components/Organisms/Modules/Workflow.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mCAAmC;AACvC;;AAEA;IACI,gBAAgB;AACpB;;;;;AAKA;IACI;QACI,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;;AAGA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;;IAEI;QACI,kBAAkB;IACtB;IACA","sourcesContent":[".workflow{\n    padding: 80px 180px;\n    background-color: var(--dark-beige);\n}\n\n.phases{\n    max-width: 500px;\n}\n\n\n\n\n@media (max-width: 2560px) {\n    .workflow{\n        padding: 120px 340px;\n    }\n}\n  \n@media (max-width: 1440px) {\n    .workflow{\n        padding: 80px 180px;\n    }\n}\n\n\n@media (max-width: 1024px) {\n    .workflow{\n        padding: 80px 120px;\n    }\n}\n    \n@media (max-width: 768px) {\n\n    .workflow{\n        padding: 80px 60px;\n    }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
