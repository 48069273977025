import StackedText from "../Atoms/StackedText"
import EnlargedImage from "../Organisms/EnlargedImage"
import RefLinks from "../Organisms/Footer"
import figma from "../../assets/tools/tools-figma.png"
import airtable from "../../assets/tools/tools-airtable.png"
import fullstack from "../../assets/tools/fullstack-dev.png"
import figmaCertificate from "../../assets/Certificates/figma-certificate.png"
import airtableCertificate from "../../assets/Certificates/airtable-certified-builder.jpg"
import fullstackCertificate from "../../assets/Certificates/fullstack-dev-certificate.png"




function AboutMe(){
    return(
        <div className="about-me">
            <div>
                <h2 className="light">About Me</h2>
                <h1>Sofia Katzman</h1>
                <p>Born in Uruguay and raised in the U.S., I grew up in a family rooted in construction and homemaking. This environment sparked my curiosity about how things work and ignited a passion for building and organizing. While I initially pursued a career in logistics, I eventually shifted paths to follow my lifelong passion for art and design, leading me to software engineering.</p>
                <p>I work across a broad spectrum of services, including user experience design, web development, integrations, marketing automation, and workflow optimization; seamlessly blending creativity with technical expertise.</p>
                <p>I value meaningful relationships, believing they are essential for success. <br></br>Outside of work, I enjoy nature walks, crafts, and baking.</p> 
                <p>If you're interested in collaborating, I'd love to connect!</p>

            </div>
            <h2 className="light sm-spacer" >Certifications</h2>
            <div className="flex-container spacer xsm-spacer">
                <EnlargedImage  icon={fullstack} alt="FullStack-Developer-Certified" src={fullstackCertificate}/>
                <EnlargedImage icon={figma} alt="Figma-Certified" src={figmaCertificate}/>
                <EnlargedImage icon={airtable} alt="Airtable-Builder-Certified" src={airtableCertificate}/>
            </div>

            <div className="sm-spacer">
                <h2 className="light">Currently...</h2>
                <div className="flex-container space-between xsm-spacer">
                    <StackedText title="Learning" text="Micropython & Electronics"/>
                    <StackedText title="Reading" text="Design that Scales by Dan Mall"/>
                    <StackedText title="Building" text="A feeding alarm system for my pup"/>
                    <StackedText title="Playing" text="Little Kitty, Big City"/>
                </div>
            </div>

        </div>
    )
}

export default AboutMe