import Script from "../../Organisms/Modules/Script.js"
import {projectsData} from "../../../data/projectsData.js"

function SEMRUsh(){
    return(
        <>
            <Script project={projectsData[3]} keyword="Feature"/>
        </>
    )
}

export default SEMRUsh