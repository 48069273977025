import Script from "../../Organisms/Modules/Script.js"
import {projectsData} from "../../../data/projectsData.js"
import { useEffect } from "react";

function ImageScaling(){
    useEffect(() => {
        document.body.style.backgroundColor = '#F7F4EF';
        return () => {
          document.body.style.backgroundColor = '';
        };
      }, []);
    return(
        <>
            <Script project={projectsData[3]} keyword="Step"/>
        </>
    )
}

export default ImageScaling