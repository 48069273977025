// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ts-container{
    padding: 80px 180px;
    background-color: var(--white);
    text-align: center;

}

h2.ts{
    max-width: 600px;
}





@media (max-width: 2560px) {


    .ts-container{
        padding:180px 340px;
      }
    }

        
@media (max-width: 768px) {


    .ts-container{
        padding:60px 60px;
        }
    }


@media (max-width: 440px) {


    .ts-container{
        padding:80px 60px;
        }
    }`, "",{"version":3,"sources":["webpack://./src/components/Organisms/Modules/ToolStack.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;;AAEtB;;AAEA;IACI,gBAAgB;AACpB;;;;;;AAMA;;;IAGI;QACI,mBAAmB;MACrB;IACF;;;AAGJ;;;IAGI;QACI,iBAAiB;QACjB;IACJ;;;AAGJ;;;IAGI;QACI,iBAAiB;QACjB;IACJ","sourcesContent":[".ts-container{\n    padding: 80px 180px;\n    background-color: var(--white);\n    text-align: center;\n\n}\n\nh2.ts{\n    max-width: 600px;\n}\n\n\n\n\n\n@media (max-width: 2560px) {\n\n\n    .ts-container{\n        padding:180px 340px;\n      }\n    }\n\n        \n@media (max-width: 768px) {\n\n\n    .ts-container{\n        padding:60px 60px;\n        }\n    }\n\n\n@media (max-width: 440px) {\n\n\n    .ts-container{\n        padding:80px 60px;\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
