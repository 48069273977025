import StackedText from "./StackedText"
import EnlargedImage from "../Organisms/EnlargedImage"
import "./style.css"

function Step({eyebrow, heading, description, image, deployment}){
     
    return(
      
            <div className="flex-container spacer pp-container sm-gap"> 
            <EnlargedImage src={image} className="ist" alt={heading}/>
                <div className="">
                    <h1 className="ist">{eyebrow}</h1>
                    <h2 className="ist">{heading}</h2>
                    <p className="xsm ist">{description}</p>
                    {deployment && <StackedText title="Deplyment" text={deployment}/> }
                </div>
                               
               
            </div>
            
        
    )
}

export default Step