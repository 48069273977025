// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbnail {
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .thumbnail:hover {
    transform: scale(1.05); 
  }
  
  .img-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .img-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
  }
  
  .enlarged-image {
    width: 100%; 
    height: 100%; 
    max-width: 90%; 
    max-height: 800px;
    min-width: 600px; 
    min-height: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    object-fit: contain; 
  }
  
  .img-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .img-close-button:hover {
    background-color: rgba(0, 0, 0, 0.85);
  }`, "",{"version":3,"sources":["webpack://./src/components/Organisms/enlargedImage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,+BAA+B;EACjC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,yCAAyC;IACzC,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,8BAA8B;IAC9B,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,qCAAqC;EACvC","sourcesContent":[".thumbnail {\n    cursor: pointer;\n    border-radius: 8px;\n    transition: transform 0.3s ease;\n  }\n  \n  .thumbnail:hover {\n    transform: scale(1.05); \n  }\n  \n  .img-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7); \n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .img-content {\n    position: relative;\n    max-width: 90%;\n    max-height: 90%;\n    overflow: hidden;\n  }\n  \n  .enlarged-image {\n    width: 100%; \n    height: 100%; \n    max-width: 90%; \n    max-height: 800px;\n    min-width: 600px; \n    min-height: 400px;\n    border-radius: 8px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);\n    object-fit: contain; \n  }\n  \n  .img-close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: rgba(0, 0, 0, 0.7);\n    color: white;\n    border: none;\n    border-radius: 50%;\n    width: 30px;\n    height: 30px;\n    font-size: 18px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .img-close-button:hover {\n    background-color: rgba(0, 0, 0, 0.85);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
