// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .features{
    margin: 80px 180px;
} */

.features-container{
    background-color: var(--light-beige);
    padding: 40px 0;
}





@media (max-width: 2560px) {

    .features-container{
        padding: 120px 340px;
      }
       }


@media (max-width: 1440px) {

.features-container{
    padding: 120px 180px;
    }
}


@media (max-width: 1024px) {

.features-container{
    padding: 120px 120px;
    }
}

@media (max-width: 768px) {

    .features-container{
        padding: 80px 60px;
        }
    }`, "",{"version":3,"sources":["webpack://./src/components/Organisms/Modules/InfoSteps.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,oCAAoC;IACpC,eAAe;AACnB;;;;;;AAMA;;IAEI;QACI,oBAAoB;MACtB;OACC;;;AAGP;;AAEA;IACI,oBAAoB;IACpB;AACJ;;;AAGA;;AAEA;IACI,oBAAoB;IACpB;AACJ;;AAEA;;IAEI;QACI,kBAAkB;QAClB;IACJ","sourcesContent":["/* .features{\n    margin: 80px 180px;\n} */\n\n.features-container{\n    background-color: var(--light-beige);\n    padding: 40px 0;\n}\n\n\n\n\n\n@media (max-width: 2560px) {\n\n    .features-container{\n        padding: 120px 340px;\n      }\n       }\n\n\n@media (max-width: 1440px) {\n\n.features-container{\n    padding: 120px 180px;\n    }\n}\n\n\n@media (max-width: 1024px) {\n\n.features-container{\n    padding: 120px 120px;\n    }\n}\n\n@media (max-width: 768px) {\n\n    .features-container{\n        padding: 80px 60px;\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
