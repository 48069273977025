import Step from "../../Atoms/Step"
import Description from "./Description"
import ContactForm from "../ContactForm.js"

function Script({ project, keyword }) {
    let itemCount = 0
    console.log(project)

    return (
        <div >
            <Description project={project} />
            <div className="xsm-spacer bg-dark sp">
                {project.features.map((feature, index) => {
                    itemCount++;
                    return (
                        <div key={`project-${feature.name}`}>
                            <Step 
                                eyebrow={`${keyword} ${itemCount}`}
                                heading={feature.name}
                                description={feature.descriptions}
                                input={feature.input}
                                output={feature.output}
                                image={feature.image}
                                deployment={feature.deployment}
                            />
                        </div>
                    );
                })}
            </div>
            <ContactForm />
        </div>
    )
}

export default Script;