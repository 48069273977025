import Projects from "../Organisms/Projects"

function Work(){
    return(
        <div className="work-container">
            <Projects />
        </div>
    )
}

export default Work