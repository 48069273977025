// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-container{
    align-items: center;
    justify-content: center;
    margin: 16% 12% 20% 12%;
}

.ref-links{
    gap: 16px;
}

img.sk-headshot {
    max-width: 100%; 
    height: 30%;    
    width: 30%;
    padding-right: 10%;
}



@media (max-width: 425px) {
.img.sk-headshot{
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Organisms/intro.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,eAAe;IACf,WAAW;IACX,UAAU;IACV,kBAAkB;AACtB;;;;AAIA;AACA;IACI,SAAS;EACX;AACF","sourcesContent":[".intro-container{\n    align-items: center;\n    justify-content: center;\n    margin: 16% 12% 20% 12%;\n}\n\n.ref-links{\n    gap: 16px;\n}\n\nimg.sk-headshot {\n    max-width: 100%; \n    height: 30%;    \n    width: 30%;\n    padding-right: 10%;\n}\n\n\n\n@media (max-width: 425px) {\n.img.sk-headshot{\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
