import StackedText from "../../Atoms/StackedText"
import EnlargedImage from "../../Organisms/EnlargedImage"
import "./Description.css"

function Description({project}){
    return(
        <div className="pp-container">
            <EnlargedImage src={project.image} className="pp-img" alt={`${project.name} img`}/>
            <div >
                <h1 className="pp-description">{project.name}</h1>
                <p className="pp-description">{project.summary}</p>
                <div className="flex-container st">
                {project.role && <StackedText title={"My Role"} text={project.role}/>}
                {project.client && <StackedText title={"Client"} text={project.client}/>}
                {project.platforms && <StackedText title={"Platforms"} text={project.platforms}/>}
                {project.toolslist && <StackedText title={"Tools"} text={project.toolslist}/>}
                {project.technologies && <StackedText title={"Technologies"} text={project.technologies}/>}
                {project.apis && <StackedText title={"APIs"} text={project.apis}/>}



                </div>
                {project.project_page_link && <button onClick={() => window.location.href=project.project_page_link} className="button pp-description">
                    {project.project_page_copy}
                </button>}
            </div>
        </div>
    )
}

export default Description
