// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-container{
    margin: 84px 0px;
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.proj-arrow {
    max-width:100%; 
    height: 350px;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding:60px;
}

.proj-img{
    max-width: 80%;
    max-height: 450px;
    cursor: pointer;
    transition: transform 0.3s ease
}
  
  .proj-img:hover {
    transform: scale(1.05);
}

.next{
    padding-left: 0px;
}

.back{
    padding-right: 0px;
}

@media (max-width: 1024px) {
.project-container{
    margin: 48px 0px;
}
.proj-img{
        max-width: 65%;
    }}

@media (max-width: 425px) {
    .proj-arrow {
        padding: 20px;
        height: 240px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Organisms/Project.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;AAEF;IACI,cAAc;IACd,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf;AACJ;;EAEE;IACE,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;AACA;IACI,gBAAgB;AACpB;AACA;QACQ,cAAc;IAClB,CAAC;;AAEL;IACI;QACI,aAAa;QACb,aAAa;IACjB;AACJ","sourcesContent":[".project-container{\n    margin: 84px 0px;\n}\n\n.carousel-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n.proj-arrow {\n    max-width:100%; \n    height: 350px;\n    background-color: transparent;\n    border: none;\n    font-size: 2rem;\n    cursor: pointer;\n    padding:60px;\n}\n\n.proj-img{\n    max-width: 80%;\n    max-height: 450px;\n    cursor: pointer;\n    transition: transform 0.3s ease\n}\n  \n  .proj-img:hover {\n    transform: scale(1.05);\n}\n\n.next{\n    padding-left: 0px;\n}\n\n.back{\n    padding-right: 0px;\n}\n\n@media (max-width: 1024px) {\n.project-container{\n    margin: 48px 0px;\n}\n.proj-img{\n        max-width: 65%;\n    }}\n\n@media (max-width: 425px) {\n    .proj-arrow {\n        padding: 20px;\n        height: 240px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
