// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-screen{
    position: "fixed";
    top: 0;
    left: 0;
    width: "100%";
    height: "100%";
    background: "#fff";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    z-index: 9999;
}

.work-container{
    padding-bottom: 40px;
}

.about-me{
    padding: 80px 180px;
}


@media (max-width: 2560px) {
    .about-me{
        padding: 120px 340px;
    }
}
  
@media (max-width: 1440px) {
    .about-me{
        padding: 80px 180px;
    }
}


@media (max-width: 1024px) {
    .about-me{
        padding: 80px 120px;
    }
}
    
@media (max-width: 768px) {

    .about-me{
        padding: 80px 60px;
    }
    }`, "",{"version":3,"sources":["webpack://./src/components/Pages/styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,MAAM;IACN,OAAO;IACP,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI;QACI,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;;AAGA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;;IAEI;QACI,kBAAkB;IACtB;IACA","sourcesContent":[".loading-screen{\n    position: \"fixed\";\n    top: 0;\n    left: 0;\n    width: \"100%\";\n    height: \"100%\";\n    background: \"#fff\";\n    display: \"flex\";\n    justify-content: \"center\";\n    align-items: \"center\";\n    z-index: 9999;\n}\n\n.work-container{\n    padding-bottom: 40px;\n}\n\n.about-me{\n    padding: 80px 180px;\n}\n\n\n@media (max-width: 2560px) {\n    .about-me{\n        padding: 120px 340px;\n    }\n}\n  \n@media (max-width: 1440px) {\n    .about-me{\n        padding: 80px 180px;\n    }\n}\n\n\n@media (max-width: 1024px) {\n    .about-me{\n        padding: 80px 120px;\n    }\n}\n    \n@media (max-width: 768px) {\n\n    .about-me{\n        padding: 80px 60px;\n    }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
