import pjPlaceholder from "../../src/assets/project-placeholder.png";
import featurePlaceholder from "../../src/assets/feature-placeholder.jpg"
import stepPlaceHolder from "../../src/assets/StepPlaceholder.png"


import stackerlogo from "../../src/assets/App/stacker_logo.png";
import airtablelogo from "../../src/assets/App/airtable_logo.png";
import zapierlogo from "../../src/assets/App/zapier_logo.png";


import tymooCover from "../../src/assets/Tymoo/Tymoo_cover.png"
import tapDemo from "../../src/assets/Tymoo/TAPDemo.gif"
import contentBrief from "../../src/assets/Tymoo/InitialContentBriefFeatureGif.gif"
import contentStructure from "../../src/assets/Tymoo/ContentStructureFeatureGif.gif"
import newBlogPost from "../../src/assets/Tymoo/NewBlogPostFeatureGif.gif"
import blogRewrite from "../../src/assets/Tymoo/BlogReWriteFeatureGif.gif"
import blogMetrics from "../../src/assets/Tymoo/BlogMetricsFeature.png"
import keywordSuggestion from "../../src/assets/Tymoo/KeywordsFeature.png"


import assetGenCover from "../../src/assets/Clubs/ClubsAssetGeneratorCoverGif.gif"
import seriesGenFeat from "../../src/assets/Clubs/SeriesGeneratorFeatureGif.gif"
import bulkGenFeat from "../../src/assets/Clubs/BulkGeneratorFeatureGif.gif"
import assetProcessFlow from "../../src/assets/Clubs/ClubsAssetProcessFlow.png"

import recycleQCover from "../../src/assets/RecycleQuest/RecycleQuestHeroGif.gif"
import addFacility from "../../src/assets/RecycleQuest/add-facility.gif"
import gemCollection from "../../src/assets/RecycleQuest/gem-collection.gif"
import leaderboard from "../../src/assets/RecycleQuest/leaderboard.png"
import loginSignup from "../../src/assets/RecycleQuest/login-signup.gif"
import redeemRecycling from "../../src/assets/RecycleQuest/redeem-recycling.gif"
import searchFacility from "../../src/assets/RecycleQuest/search-facilities.gif"
import dailyQuiz from "../../src/assets/RecycleQuest/daily-quiz.gif"



import imageScaleCover from "../../src/assets/ImageScaleScript/ImageScalingScriptCoverGif.gif"
import figmaExport from "../../src/assets/ImageScaleScript/FigmaExport.gif"
import openScript from "../../src/assets/ImageScaleScript/ScalingScriptOpenGif.gif"
import scriptOutput from "../../src/assets/ImageScaleScript/ScriptOutputGif.gif"


export const projectsData = [
    {
        name: "Tymoo - Tidal Wave Content",
        image: tymooCover, 
        description: "An AI-powered tool that transforms form inputs into brand documentation—automating target audience personas, content waves, and blog writing. Built using Airtable, Zapier, ChatGPT, and API integrations.",
        tags: ["Fullstack Development", "Automation"],
        cta1_copy:"Read More >",
        cta1_url:"/work/tymoo",
        cta2_copy:"View Live >",
        cta2_url:"https://tidalwavecontent.com/",
        project_page_link: "https://tidalwavecontent.com/",
        project_page_copy: "view live",
        summary: "A content marketing platform that helps brand marketers, agencies, and creators grow brand awareness and build customer loyalty. I automated the creation of key documents, contributed to the interface design, and wrote scripts to track blog performance and suggest keywords.",
        role: "Full-Stack Developer, Project Manager",
        toolslist: "Airtable, Zapier, Stacker, SEMrush, OpenAI, Claude, Basecamp",
        technologies: "Javascript, Python, OpenAI, Webhooks, Airtable Scripting",
        apis: "Airtable, Zapier, OpenAI, SEMrush, Google Drive, Basecamp, Anthropic",
        phasesdescription: "The initial tool delivery relied on piecemeal automation using Typeform and Zapier, which was originally implemented with scalability in mind. However, we quickly realized that focusing on scalability too early complicated the workflow and made it harder for users to access documents. We needed a stronger foundation first.  I transitioned the process to Airtable forms, streamlining data collection and improving efficiency. Later, I integrated this input method into the TyMoo platform, allowing users to access all their documents and tools in one place. This evolution transformed a complex system into a streamlined, scalable solution.",
        phases: [
            {
                strong: "Typeform",
                text: "Have users fill out Typeforms to provide necessary data to generate foundational documents. Automate this flow and open beta access."
            },
            {
                strong: "Airtable Forms",
                text: "Integrate Airtable forms to process in order to centralize data collection. Use forms for directly-with-consumer (on the phone) foundational document creation."
            },
            {
                strong: "Stacker",
                text: "Scrap Typeform and bring forms into TyMoo application. Users sign up and now have access to all of their generated documents directly on the Tymoo Platform. Once documents are completed, they can generate content waves and write blogs with our AI assistant."
            }
        ],
        toolstack: "While I worked within the tools preferred by my client and navigated certain limitations, many custom solutions still had to be developed with code to meet the platform's unique needs. As the sole developer, I prioritized low-code solutions where possible to ensure maintainability in my absence, but also implemented custom coding solutions to deliver a scalable and efficient system.",
        tools: [
            {
                logo: airtablelogo,
                name: "Airtable",
                description: "Airtable serves as the backbone of the platform, functioning as the central database. I leveraged its full range of capabilities, including automations, scripts, forms, and interfaces, to streamline data collection and workflow management. These tools played a critical role in ensuring the platform's efficiency and scalability, allowing users to interact seamlessly with their data and automating key processes behind the scenes.",
            },
            {
                logo: stackerlogo,
                name: "Stacker",
                description: "Stacker serves as the front-end interface for TyMoo, where users interact with the platform by filling out forms and accessing their generated content. I integrated all forms into Stacker, building out the entire user interface to provide seamless access to personas, content waves, and blog analytics. Prior to my involvement, the TyMoo interface was primarily used for blog post creation and tracking, but I expanded its functionality to serve as a central hub for the entire platform.",
            },
            {
                logo: zapierlogo,
                name: "Zapier",
                description: "Zapier is the automation platform that ties everything together. I optimized workflows by creating subzaps—reusable components that function like coding functions. These subzaps are embedded into multiple zaps, streamlining the process and making the platform more efficient by reducing redundant tasks.",
            }
        ],
        features: [
            {
                name: "Generate Persona",
                descriptions: "Automatically generate a detailed target audience persona based on user input, helping marketers align their brand messaging with their ideal customer profile. This feature uses AI to produce custom personas that can be tailored for different marketing strategies.",
                input: "User answers 22 questions",
                output:"Generated Persona \n Generated Brand Promise",
                image: tapDemo,
            },
            {
                name: "Generate Content Brief",
                descriptions: "Automatically generate a detailed target audience persona based on user input, helping marketers align their brand messaging with their ideal customer profile. This feature uses AI to produce custom personas that can be tailored for different marketing strategies.Create a series of content suggestions based on the user's marketing goals and previously generated target audience. The Content Wave feature provides an overview of potential blog topics, social media posts, and other marketing content, helping users maintain a consistent content flow.",
                input: "User answers 22 questions",
                output:"Content Brief \n Content Structure \n 7 Blog Ideas",
                image: contentBrief,
            },
            {
                name: "Generate Content Structure",
                descriptions: "Automatically generate a detailed target audience persona based on user input, helping marketers align their brand messaging with their ideal customer profile. This feature uses AI to produce custom personas that can be tailored for different marketing strategies.Create a series of content suggestions based on the user's marketing goals and previously generated target audience. The Content Wave feature provides an overview of potential blog topics, social media posts, and other marketing content, helping users maintain a consistent content flow.",
                input: "User answers 22 questions",
                output:"Content Brief \n Content Structure \n 7 Blog Ideas",
                image: contentStructure,
            },
            {
                name: "Write Blog",
                descriptions: "This feature leverages AI to write original blog content based on an overview and seed keywords. Users can generate a full blog post that aligns with their SEO strategy and brand voice, saving significant time in content creation. This uses the previously generated foundational documents (persona and content structure) as a knowledge base.",
                input: "Blog Overview \n Seed Keyword",
                output:"Blog Post",
                image: newBlogPost,
            },
            {
                name: "ReWrite Blog",
                descriptions: "The ReWrite Blog feature uses AI to refine and improve existing blog content, enhancing readability and optimizing for SEO. Users can update older posts or adjust their messaging to improve engagement and ranking.",
                input: "Published Blog Post",
                output:"Rewritten Blog Post \n Suggested Keywords & Title",
                image: blogRewrite,
            },
            {
                name: "Blog Analytics",
                descriptions: "Track the performance of published blogs by monitoring key metrics such as SEO ranking, traffic, and engagement. This feature provides weekly insights, helping users optimize their content strategy for better performance over time.",
                input: "Published Blog Posts",
                output:"Organic Keywords \n Organic Traffic \n Organic Cost \n Metric Differences",
                image: blogMetrics,
            },
            {
                name: "Suggested Keywords",
                descriptions: "This tool analyzes the content and suggests keywords based on a seed keyword or existing content, helping marketers improve their SEO. Users can approve or deny these suggestions and track their effectiveness through the platform’s analytics tools.",
                input: "Published Blog Posts",
                output:"Keyword Volume \n Keyword Difficulty \n Keyword CPC",
                image: keywordSuggestion,
            },
        ]


    },
    {
        name: "Clubs Asset Generator",
        image: assetGenCover, 
        description: "Figma plugin developed for Scholastic Book Clubs. Generate series of brand-specific banners directly or batch import from Air Table entries! ",
        tags: ["Figma Plugin","Airtable","TS"],
        cta1_copy:"Read More >",
        cta1_url:"/work/asset-generator",
        // cta2_copy:"View Live >",
        // cta2_url:"",
        // project_page_link: "https://www.tidalwavecontent.com/",
        // project_page_copy: "view live",
        summary: "Figma plugin developed for Scholastic Book Clubs. Generate series of brand-specific banners directly or batch import from Air Table entries! ",
        role: "Product Designer and Full Stack Developer",
        toolslist: "Figma, AirTable, Basecamp, Zapier",
        technologies: "Javascript, React,  HTML, CSS",
        apis: "Figma, AirTable, Basecamp, Google Docs",
        phasesdescription: "The entire idea behind Clubs Banner Generator was making it easy for our designers to create banners. Having had to create banners myself, I knew first hand what all of the difficulties were when trying to turn around deliverables quickly. Using these frustrations, I found solutions through making a custom built Figma plugin for my team! The idea quickly took a life of it’s own and became a full operations automation project.",
        phases: [
            {
                strong: "Templatization",
                text: "Figma Component Library Buildout"
            },
            {
                strong: "Initial Programming",
                text: "Develop proof of concept of banner generation via UI"
            },
            {
                strong: "UX/UI Redesign",
                text: "Visual refresh along with UX improvements for more intuitive use."
            },
            {
                strong: "Automation via Integration",
                text: "Integrate Air Table to Figma"
            },
            {
                strong: "Automation via Zapier",
                text: "Use Air Table views to trigger Zapier automations that integrate Basecamp (our project management platform)"
            }
        ],
        // toolstack: "While I worked within the tools preferred by my client and navigated certain limitations, many custom solutions still had to be developed with code to meet the platform's unique needs. As the sole developer, I prioritized low-code solutions where possible to ensure maintainability in my absence, but also implemented custom coding solutions to deliver a scalable and efficient system.",
        // tools: [
        //     {
        //         logo: airtablelogo,
        //         name: "Airtable",
        //         description: "Airtable serves as the backbone of the platform, functioning as the central database. I leveraged its full range of capabilities, including automations, scripts, forms, and interfaces, to streamline data collection and workflow management. These tools played a critical role in ensuring the platform's efficiency and scalability, allowing users to interact seamlessly with their data and automating key processes behind the scenes.",
        //     },
        //     {
        //         logo: stackerlogo,
        //         name: "Stacker",
        //         description: "Stacker serves as the front-end interface for TyMoo, where users interact with the platform by filling out forms and accessing their generated content. I integrated all forms into Stacker, building out the entire user interface to provide seamless access to personas, content waves, and blog analytics. Prior to my involvement, the TyMoo interface was primarily used for blog post creation and tracking, but I expanded its functionality to serve as a central hub for the entire platform.",
        //     },
        //     {
        //         logo: zapierlogo,
        //         name: "Zapier",
        //         description: "Zapier is the automation platform that ties everything together. I optimized workflows by creating subzaps—reusable components that function like coding functions. These subzaps are embedded into multiple zaps, streamlining the process and making the platform more efficient by reducing redundant tasks.",
        //     }
        // ],
        features: [
            {
                name: "Series Generator",
                descriptions: "The first series of the plugin allowed users to just generate a basic set of assets with selected copy. The styling was minimal and unsightly, but it got the work done. It allowed me to prove that the designers were hungry for a tool to simplify their workflow.",
                input: "Digital Designers",
                // output:"Generated Persona \n Generated Brand Promise",
                image: seriesGenFeat,
            },
            {
                name: "Bulk Generator",
                descriptions: "The second iteration of the plugin made calls to the Air Table API from the Figma API, allowing me to be able to extract a large list of assets that needed to be generated and programmatically setting it up so that they'd be generated after clicking “sync”. ",
                input: "Art Director",
                // output:"Content Brief \n Content Structure \n 7 Blog Ideas",
                image: bulkGenFeat,
            },
            {
                name: "Zapier Automations",
                descriptions: "The third iteration of the plugin leveraged Air Table views and Zapier alongside Basecamp to incorporate a workflow management component and notification system. ",
                input: "Board / VP Executives, Adjacent Business Units",
                // output:"Blog Post",
                image: assetProcessFlow,
            },
            
        ]


    },
    {
        name: "RecycleQuest",
        image: recycleQCover,
        description: "A gamified platform designed to promote recycling awareness, created for 'Girls in Tech: Hack for the Environment'.",
        tags: ["Gamification", "Hackathon", "Ruby on Rails", "React"],
        cta1_copy: "Read More >",
        cta1_url: "/work/recyclequest",
        project_page_link: "https://ruby-rails-blazer-part-ii.onrender.com/",
        project_page_copy: "View Live",
        summary: "Promotes recycling awareness through a fun and engaging competitive environment.",
        role: "Front End Developer",
        technologies: "React, Ruby on Rails, PostgreSQL",
        features: [
            {
                name: "Secure Login and Signup",
                descriptions: "User accounts are protected with secure, encrypted password storage powered by BCrypt, along with user-specific sessions.",
                image: loginSignup
            },
            {
                name: "Gamified Recycling Knowledge",
                descriptions: "A click-and-find game makes learning about recycling fun and interactive.",
                image: gemCollection
            },
            {
                name: "Daily Quizzes",
                descriptions: "Test your recycling knowledge with daily quizzes based on gems you've collected. Earn points for correct answers!",
                image: dailyQuiz
            },
            {
                name: "Leaderboard",
                descriptions: "A live leaderboard ranks users globally based on their contributions and points.",
                image: leaderboard
            },
            {
                name: "Log Recycling Contributions",
                descriptions: "Log your recycling contributions to earn points. As part of our proposal, these contributions can be validated by participating recycling plants.",
                image: redeemRecycling
            },
            {
                name: "Search Recycling Facilities",
                descriptions: "Find nearby recycling facilities and filter by the types of materials they accept.",
                image: searchFacility
            },
            {
                name: "Contribute New Facilities",
                descriptions: "Help expand the database by adding new recycling facilities and earn points for your contributions.",
                image: addFacility
            }
        ]
    },
    {
        name: "Image Scaling Script",
        image: imageScaleCover, 
        description: "A bulk processing tool that addresses Figma's export quality issues by scaling down assets based on a designer's input, assumed to match the export scale used in Figma, ensuring high-quality assets without manual resizing.",
        tags: ["Adobe Script", "Javascript"],
        cta1_copy:"Read More >",
        cta1_url:"/work/script/image-scaling",
        project_page_link: "https://github.com/sofiakatzman/PhotoshopScripts/blob/main/ImageScaleScript.js",
        project_page_copy: "see code",
        client: "Scholastic",
        platforms: "Adobe Scrips",
        summary: "A bulk processing tool that addresses Figma's export quality issues by scaling down assets based on a designer's input, assumed to match the export scale used in Figma, ensuring high-quality assets without manual resizing.",
       features: [
            {
                name: "Download Assets from Figma",
                descriptions: "Users first export their assets from Figma using a scale, and save them inside one single folder. This scale ensures Figma allocates the necessary browser memory to exporting this asset and ensures it retains it's original quality, unlike exporting at 1X which sometimes causes blurry assets.",
                image: figmaExport,
            },
            {
                name: "Open Script on Adobe",
                descriptions: "Once designers run this script, they are prompted to select the folder their assets are located in, as well as the scale value they used when exporting their assets.",
                image: openScript,
            },
            {
                name: "Script Runs and Creates a “Processed Folder” with output",
                descriptions: "Upon script completion, a folder is created inside the folder where the assets were located, which includes all of the newly scaled down assets. The designers then deliver these assets as necessary. ",
                image: scriptOutput,
            },
        ]
    },
    // {
    //     name: "Milestones",
    //     image: "https://media.istockphoto.com/id/1276686973/vector/continuous-line-drawing-champagne-bottle.jpg?s=612x612&w=0&k=20&c=Osd_Q4WO5IYQXDnWt64ym--WiEh_9mwgNO90ppAf-9s=",
    //     description: "A social platform to illustrate life accomplishments via milestones, helping users understand their journey better.",
    //     tags: ["Social Platform", "Python", "React"],
    //     cta1_copy: "Read More >",
    //     cta1_url: "https://github.com/sofiakatzman/Milestones",
    //     project_page_link: "https://github.com/sofiakatzman/Milestones",
    //     project_page_copy: "GitHub",
    //     summary: "A platform that helps users track their life milestones.",
    //     role: "Full Stack Developer",
    //     technologies: "React, Python, Flask, WebSocket.io",
    //     features: [
    //         {
    //             name: "Milestone Tracking",
    //             descriptions: "Users can add and track their life milestones and accomplishments.",
    //             image: pjPlaceholder
    //         },
    //         {
    //             name: "User Updates",
    //             descriptions: "See other user's milestones",
    //             image: pjPlaceholder
    //         },
    //         {
    //             name: "View User Profiles & Follow",
    //             descriptions: "Follow your favorite users so you get notified when they make a new post!",
    //             image: pjPlaceholder
    //         },
    //     ]
    // },
    
    // {
    //     name: "EventManager",
    //     image: "https://media.istockphoto.com/id/1197573398/vector/continuous-one-line-drawing-clock-icon-with-doodle-handdrawn-style-on-white-background.jpg?s=612x612&w=0&k=20&c=9AqkHQ2FILkhU-M7mn3K-zDUX7-WX6NO93FoDSuThCc=",
    //     description: "A CLI app to streamline hiring and booking of event staff. Developed for myself for closing out events I managed for Provocateur NY.",
    //     tags: ["CLI", "Python", "SQLAlchemy"],
    //     cta1_copy: "Read More >",
    //     cta1_url: "https://github.com/sofiakatzman/Event-Manager",
    //     project_page_link: "https://github.com/sofiakatzman/Event-Manager",
    //     project_page_copy: "GitHub",
    //     summary: "Streamlines event staff hiring and booking processes.",
    //     role: "Back End Developer",
    //     technologies: "Python, SQL, SQLAlchemy, CLI",
    //     features: [
    //         {
    //             name: "Add Events & Staff Them",
    //             descriptions: "Streamlines hiring and booking of event staff with efficient data management.",
    //             image: pjPlaceholder
    //         },
    //         {
    //             name: "Event Closeout and Tipouts",
    //             descriptions: "Different percentages earn different amounts, so event closeouts calculate exactly how much everyone is owed.",
    //             image: pjPlaceholder
    //         },
    //         {
    //             name: "Event CRM",
    //             descriptions: "Quickly find and save events based on staff, dates, types, etc. ",
    //             image: pjPlaceholder
    //         },
    //         // Add other features here as needed
    //     ]
    // },
    // {
    //     name: "EduGuide",
    //     image: pjPlaceholder, 
    //     description: "A video platform with a voice-activated AI Chatbot, allowing users to explore subjects with minimal physical input and maximum accessibility.",
    //     tags: ["Voice Activation", "AI Chatbot", "React", "Python", "Hackathon"],
    //     cta1_copy: "Read More >",
    //     cta1_url: "",
    //     project_page_link: "https://github.com/sofiakatzman/EduGuide", 
    //     project_page_copy: "GitHub",
    //     summary: "A video platform with an easy-to-access, voice-activated AI Chatbot allowing users to understand topics on their terms. The project emphasizes minimal physical interaction and accessibility, featuring large buttons and voice command input.",
    //     role: "Full Stack Developer",
    //     technologies: "React, Python, Flask, ChatGPT API",
    //     features: [
    //         {
    //             name: "Voice-Activated Chatbot",
    //             descriptions: "Integrated with ChatGPT, the chatbot uses microphone input to understand user queries, allowing hands-free exploration of subjects.",
    //             image: pjPlaceholder
    //         },
    //         {
    //             name: "Accessibility-Focused Design",
    //             descriptions: "Features large, accessible buttons to reduce physical strain and increase ease of use for individuals with limited mobility.",
    //             image: pjPlaceholder
    //         },
    //         {
    //             name: "Minimal Direct User Input",
    //             descriptions: "Designed with guiding principles of minimal direct input, allowing users to navigate the platform and engage with content using voice controls.",
    //             image: pjPlaceholder
    //         }
    //     ],
    //     achievements: [
    //         "Submitted as a Hackathon project in October 2023, showcasing the integration of voice-activated AI with an accessible UI.",
    //         "Utilized ChatGPT to deliver tailored subject explanations, catering to diverse learning styles and accessibility needs.",
    //         "Implemented a seamless voice control system for enhanced accessibility and user engagement."
    //     ]
    // },
    // {
    //     name: "Design Automation Plugins Collection",
    //     image: pjPlaceholder, 
    //     description: "A suite of Figma plugins designed to streamline workflows by integrating Airtable data, automating asset creation, and simplifying data management for design teams.",
    //     tags: ["Figma Plugin", "Automation", "Airtable"],
    //     cta1_copy: "Read More >",
    //     cta1_url: "",
    //     project_page_link: "", 
    //     project_page_copy: "view details",
    //     platforms: "Figma, Airtable",
    //     summary: "A collection of Figma plugins that automate various design workflows for Scholastic's Digital Design teams. By connecting Airtable data directly to Figma, these plugins reduce manual input, streamline operations, and boost productivity.",
    //     role: "Product Designer and Developer",
    //     toolslist: "Figma, Airtable, API Integrations",
    //     technologies: "JavaScript, Node.js, Airtable API, Figma API",
    //     features: [
    //         {
    //             name: "Category Module Generator",
    //             descriptions: "Designed for eCommerce assets, this plugin automates the creation of designer-specific modules and sends the Figma node ID back to Airtable, ensuring assets are properly linked and easily previewable.",
    //             image: featurePlaceholder,
    //         },
    //         {
    //             name: "Storyvoice Schedule Generator",
    //             descriptions: "Pulls live event and editorial data from Airtable to auto-populate a Storyvoice Schedule template, with event details pre-filled for easy design and customization.",
    //             image: featurePlaceholder,
    //         },
    //         {
    //             name: "Scholastic Dollars Module Generator",
    //             descriptions: "This plugin uses product IDs and the Scholastic API to fetch product images, automating the creation of eStatement emails.",
    //             image: featurePlaceholder,
    //         },
    //         {
    //             name: "Series Generator",
    //             descriptions: "The first in the series of plugins, allowing users to generate a basic set of assets with selected copy to speed up repetitive tasks.",
    //             image: featurePlaceholder,
    //         }
    //     ],
    //     achievements: [
    //         "Implemented a custom proxy server to handle secure data requests between Figma and Airtable, improving data flow and compliance.",
    //         "Created a streamlined workflow that reduces errors and accelerates design operations by connecting Airtable data directly to Figma.",
    //         "Significantly decreased asset creation time, allowing design teams to focus on creative tasks."
    //     ]
    // },
    
];

