// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ref-links {
    gap: 12px;
    display: flex;
    margin-top: 32px;
}

.work-cards {
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Molecules/styles.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".ref-links {\n    gap: 12px;\n    display: flex;\n    margin-top: 32px;\n}\n\n.work-cards {\n    flex-wrap: wrap;\n    justify-content: center;\n    margin: 40px 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
