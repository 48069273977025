import React, { useState } from 'react';
import './enlargedImage.css';

function EnlargedImage({ src, alt, className, icon}){
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true); 
  };

  const closeModal = () => {
    setIsOpen(false); 
  };

  const newClassName = `${className} thumbnail`
  const imageSrc = icon || src

  return (
    <>
      {/* Normal Image */}
      <img 
        src={imageSrc}
        alt={alt} 
        className={newClassName}
        onClick={handleClick} 
      />

      {/* Englarged View */}
      {isOpen && (
        <div className="img-overlay" onClick={closeModal}>
          <div className="img-content">
            <button className="img-close-button" onClick={closeModal}>X</button>
            <img src={src} alt={alt} className="enlarged-image" />
          </div>
        </div>
      )}
    </>
  );
};

export default EnlargedImage;