import Description from "../../Organisms/Modules/Description"
import { projectsData } from "../../../data/projectsData.js"
import { useEffect } from "react";
import Script from "../../Organisms/Modules/Script.js"
import Footer from "../../Organisms/Footer.js";


function RecycleQuest(){
    useEffect(() => {
        document.body.style.backgroundColor = '#F7F4EF';
        return () => {
          document.body.style.backgroundColor = '';
        };
      }, []);

    return(
        <div>
            <Script project={projectsData[2]} keyword="Feature"/>
            <Footer />
        </div>
    )
}

export default RecycleQuest