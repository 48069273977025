// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-container{
    display: flex;
    padding: 6px 104px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0;
    margin: 2% auto;
    width: 470px;
}

.circle-divider {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: var(--black); 
    border-radius: 50%; 
    margin: 4px 50px; 
}

@media (max-width: 768px) {
.navigation-container{
    width: 300px;
    padding: 6px 34px;
    margin: 2% auto;

}
.circle-divider{
    margin: 4px 0px 4px 32px;
}
}

@media (max-width: 425px) {
    .navigation-container{
        width: 230px;
        padding: 6px 34px;
        margin: 2% auto;

    }
    .circle-divider{
        margin: 4px 0px 4px 18px;
    }
    }`, "",{"version":3,"sources":["webpack://./src/components/Organisms/Navigation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,mBAAmB;IACnB,SAAW;IACX,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,8BAA8B;IAC9B,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;;AAEnB;AACA;IACI,wBAAwB;AAC5B;AACA;;AAEA;IACI;QACI,YAAY;QACZ,iBAAiB;QACjB,eAAe;;IAEnB;IACA;QACI,wBAAwB;IAC5B;IACA","sourcesContent":[".navigation-container{\n    display: flex;\n    padding: 6px 104px;\n    justify-content: space-between;\n    align-items: center;\n    flex: 1 0 0;\n    margin: 2% auto;\n    width: 470px;\n}\n\n.circle-divider {\n    display: inline-block;\n    width: 6px;\n    height: 6px;\n    background-color: var(--black); \n    border-radius: 50%; \n    margin: 4px 50px; \n}\n\n@media (max-width: 768px) {\n.navigation-container{\n    width: 300px;\n    padding: 6px 34px;\n    margin: 2% auto;\n\n}\n.circle-divider{\n    margin: 4px 0px 4px 32px;\n}\n}\n\n@media (max-width: 425px) {\n    .navigation-container{\n        width: 230px;\n        padding: 6px 34px;\n        margin: 2% auto;\n\n    }\n    .circle-divider{\n        margin: 4px 0px 4px 18px;\n    }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
